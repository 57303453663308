@import '~theme/variables';

.button {
  margin-top: 4.8rem;
}

.description {
  margin-top: 2.4rem;
}

.contentContainer {
  padding-left: $space-xs;

  @include breakpoint(sm) {
    padding-left: $space-l;
  }

  @include breakpoint(md) {
    padding-left: $space-xxl;

    &.isLeftAligned {
      padding-left: $space-xxl;
    }
  }

  &.club-page {
    padding-left: 0;
  }
}

.carouselContainer {
  @include breakpoint(sm) {
    padding-left: $space-l;
  }

  @include breakpoint(md) {
    padding-left: $space-xxl;

    &.isLeftAligned {
      padding-left: $space-xxl;
    }
  }

  &.club-page {
    padding-left: 0;
  }
}
