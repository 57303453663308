@import '~theme/variables';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes leftToRight {
  0% {
    right: 0;
  }

  100% {
    right: -50rem;
  }
}

@keyframes rightToLeft {
  0% {
    right: -30rem;
  }

  100% {
    right: 0;
  }
}

@keyframes topToBottom {
  0% {
    top: 0;
  }

  100% {
    top: calc(100vh + 50px);
  }
}

@keyframes bottomToTop {
  0% {
    top: calc(100vh + 50px);
  }

  100% {
    top: 0;
  }
}

.container {
  transition: 0.3s ease-in-out;
  z-index: $z-index-sheet;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  &.isOpen {
    opacity: 1;
  }
  &.fadeIn {
    &.opening {
      opacity: 1;
      animation: fadeIn 0.3s ease-out;
    }

    &.closing {
      opacity: 0;
      animation: fadeOut 0.3s ease-out;
    }
  }

  &.fullscreen,
  &.middle {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(sm) {
      padding: 1rem;
    }
    padding: $space-s;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  z-index: $z-index-dialog;
  text-align: left; //overrides row text-align right

  @include breakpoint(sm, max) {
    width: 100%;
    overflow-y: scroll;
    margin: 0;
  }

  &.middle {
    background-color: $color-neutral-06;
    max-width: 69.8rem;
    height: 100%;
    overflow-y: auto;
    border-radius: $space-s;
    padding: $space-s;
    box-shadow: $box-shadow-01;
    position: relative;

    &.noPadding {
      padding: 0;
    }

    @include breakpoint(sm) {
      padding: $space-l;
      height: auto;
      max-height: 85%;
    }

    .iconContainer {
      right: 1rem;
      top: 1rem;
    }
  }

  &.slideBottom {
    &.isOpen {
      top: 0;
      animation: bottomToTop 0.3s ease-out;
    }

    &.closing {
      animation: topToBottom 0.3s ease-out;
    }
  }

  &.right.slideRight {
    background-color: $color-neutral-06;
    position: fixed;
    top: 0;
    right: -50rem;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;

    @include breakpoint(sm) {
      width: 53rem;
    }

    &.isOpen {
      right: 0;
      animation: rightToLeft 0.3s ease-out;
    }

    &.closing {
      right: -50rem;
      animation: leftToRight 0.3s ease-out;
    }
  }

  &.fullscreen {
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .title {
    margin-bottom: 1.6rem;

    span {
      color: $color-popup;
    }
  }

  .subtitle {
    line-height: 2.8rem;
    margin-bottom: 2rem;
    color: $color-neutral-02;

    @include breakpoint(sm) {
      margin-bottom: 3.2rem;
    }
  }

  .iconContainer {
    position: absolute;
    right: 2.4rem;
    top: 2.4rem;
    z-index: $z-index-tooltip;
    transition: $transition-01;
  }

  .imageContainer {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 20rem;
    overflow: hidden;

    @include breakpoint(sm) {
      height: 25.8rem;
      max-height: none;
    }
  }

  .headerImage {
    object-fit: cover;
  }

  .childWrapper {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    margin: -$space-s;
    padding: $space-s;
    margin-top: 0;
    padding-top: 0;

    @include breakpoint(sm) {
      margin: -$space-l;
      padding: $space-l;
    }

    &.noPadding {
      padding: 0;
      margin: 0;
    }
  }
}
