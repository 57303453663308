@import "~theme/variables";

.container {
  position: relative;
  margin-top: $space-s;
}

.list {
  margin-left: -.8rem;

  li:nth-child(n+2) {
    margin-top: .8rem;
  }
}
