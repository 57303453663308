@import "~theme/variables";

.iconButton {
  border-radius: 50%;
  cursor: pointer;
  background-color: $color-neutral-05;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color $transition-01;

  &:hover {
    background-color: $color-primary;
  }

  @include breakpoint(sm) {
    width: 4.8rem;
    height: 4.8rem;
  }
}