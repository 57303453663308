@import '~theme/variables';

@layer components {
  .overlay {
    position: fixed;
    background: $color-neutral-01;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-sheet-overlay;
    backdrop-filter: blur(0.4rem);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    opacity: 0;

    &.isOpen {
      opacity: 0.6;
    }

    &.opening {
      opacity: 0.6;
      animation: opacityOn 0.3s ease-out;
    }

    &.closing {
      opacity: 0;
      animation: opacityOff 0.3s ease-out;
    }

    &.full {
      opacity: 0;

      &.isOpen {
        opacity: 1;
      }

      &.opening {
        opacity: 1;
        animation: opacityOnFull 0.3s ease-out;
      }

      &.closing {
        opacity: 0;
        animation: opacityOffFull 0.3s ease-out;
      }
    }
  }

  @keyframes opacityOn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.6;
    }
  }

  @keyframes opacityOff {
    0% {
      opacity: 0.6;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes opacityOnFull {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes opacityOffFull {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
